export default [
    {
      header: 'Dashboards',
      icon: 'HomeIcon',
      children: [
        {
          title: 'eCommerce',
          route: 'dashboard-ecommerce',
          icon: 'ShoppingCartIcon',
          resource: 'Admin',
          action: 'read',
        },
        {
          title: 'Analytics',
          route: 'dashboard-analytics',
          icon: 'ActivityIcon',
          resource: 'Admin',
          action: 'read',
        },
      ],
    },
  ]
  